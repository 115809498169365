.App {
  text-align: center;
}

.wp-logo {
  max-width: 30px;
  height: auto;
  margin-bottom: -10px;
  margin-left: 5px;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
  max-width: 300px;
  height: auto;
}

.pasoscontenedor{
  flex-direction: row !important;
  margin-top: 15px;
}

.formulario{
  background-color: #fff;
  padding: 25px;
}

.formulario div{
  margin-right: 25px;
}

.paso-img{
  margin-right: 10px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header p{
  font-size: calc(2px + 2vmin);
}

.App-link {
  color: #61dafb;
}
.textfield{
  width: 100%;
}
.textfield label, .textfield div input {  
  color: #fff;
}
.textfield div fieldset {  
  border-color: #fff;
}
.mitad{
  flex-direction: row !important;
  margin-top: 15px;
  width: 50vw;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
